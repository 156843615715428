define("discourse/plugins/discourse-hcaptcha/discourse/services/h-captcha-service", ["exports", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/implicit-injections", "discourse-i18n"], function (_exports, _tracking, _object, _service, _implicitInjections, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const hCaptchaService = dt7948.c(class hCaptchaService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "invalid", [_tracking.tracked], function () {
      return true;
    }))();
    #invalid = (() => (dt7948.i(this, "invalid"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "submitted", [_tracking.tracked], function () {
      return false;
    }))();
    #submitted = (() => (dt7948.i(this, "submitted"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "token", [_tracking.tracked], function () {
      return null;
    }))();
    #token = (() => (dt7948.i(this, "token"), void 0))();
    widgetId = null;
    hCaptcha = null;
    get submitFailed() {
      return this.submitted && this.invalid;
    }
    get inputValidation() {
      return _object.default.create({
        failed: this.invalid,
        reason: (0, _discourseI18n.i18n)("discourse_hCaptcha.missing_token")
      });
    }
    registerWidget(hCaptcha, id) {
      this.hCaptcha = hCaptcha;
      this.widgetId = id;
    }
    reset() {
      this.invalid = true;
      this.submitted = false;
      this.token = null;
      this.hCaptcha.reset(this.widgetId);
    }
  }, [_implicitInjections.disableImplicitInjections]);
  var _default = _exports.default = hCaptchaService;
});